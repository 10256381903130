@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: #333;
    background-color: #f8f9fa;
    overflow-x: hidden;
}


.App-header,
.App-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    background-color: #1969c0;
    border-radius: 8px;
    padding: 15px;
    color: white;
}

.header-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.App {
    text-align: center;
    padding: 15px;
}

.logo {
    width: 80px;
    height: 80px;
    background-image: url('./assets/img/logo.png');
    object-fit: cover;
    object-position: -15% 0;
    border-radius: 50%;
    transform-origin: center;
}


ul {
    padding: 0;
    list-style-type: none;
}

h4{
    text-align: right;
    margin: 20px;
}

li {
    font-size: 1rem;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
}

.list-group {
    max-height: 220px;
    overflow-y: auto;
}

.ranking-list,
.room-info,
.host-info,
.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin: 5px auto;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.text-center {
    text-align: center;
}

button.btn {
    margin-top: 0;
}

/* Add to App.css */
.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f8f9fa;
}

.auth-box {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.auth-form-container {
    margin-bottom: 1rem;
}

.auth-form-container h2 {
    margin-bottom: 1.5rem;
    text-align: center;
}

.form-control {
    margin-bottom: 1rem;
}

.alert {
    margin-bottom: 1rem;
    padding: 0.75rem;
    border-radius: 4px;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
}

.btn-link {
    padding: 0;
    text-decoration: none;
}

.btn-link:hover {
    text-decoration: underline;
}
